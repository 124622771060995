@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  padding-bottom: 2rem;
}

body {
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 120rem;
}

button::-moz-focus-inner {
  border: 0;
}

a {
  color: #385600;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.base-layout {
  padding: 1rem;
}

html {
  font-size: 62.5%;
  font-family: 'Open Sans', sans-serif;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 56.75em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

body {
  font-size: 2rem;
}

.u-color-green {
  color: #385600;
}

.u-left-align {
  text-align: left !important;
}

.u-sm-margin-top {
  margin-top: 1rem !important;
}

.u-italic {
  font-style: italic;
  font-size: 1.6rem;
}

.u-section-break {
  margin-bottom: 8rem;
}
@media only screen and (max-width: 37.5em) {
  .u-section-break {
    margin-bottom: 4rem;
  }
}
.u-section-break--small {
  margin-bottom: 5rem;
}
@media only screen and (max-width: 37.5em) {
  .u-section-break--small {
    margin-bottom: 2rem;
  }
}

.u-subtitle {
  font-size: 2.4rem;
}
@media only screen and (max-width: 37.5em) {
  .u-subtitle {
    font-size: 2rem;
  }
}

.u-star {
  font-size: 1.6rem;
  font-style: italic;
  vertical-align: super;
}

.u-bold {
  font-weight: bold;
}

.u-padding {
  padding: 1rem !important;
  border-radius: 3px !important;
}

.u-underline {
  text-decoration: underline;
}

.u-no-data {
  font-size: 2rem;
  color: #385600;
}

.about__title {
  color: #385600;
  margin: 1rem 0;
}
.about__blurb {
  word-spacing: 1px;
  text-align: justify;
}

.album {
  margin-right: 1rem;
  background: url(/static/media/loading.554c5841.gif) center center no-repeat;
}
.album__iframe {
  border: 0;
  width: 35rem;
  height: 60rem;
}

.class {
  margin-top: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .class {
    text-align: center;
  }
}
.class__description {
  margin-bottom: 2rem;
}
.class__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50rem;
  font-size: 2.4rem;
  margin-bottom: 0.8rem;
}
@media only screen and (max-width: 37.5em) {
  .class__info {
    width: 100%;
  }
}
.class__info--class {
  width: 65rem;
}
@media only screen and (max-width: 37.5em) {
  .class__info--class {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.class__title {
  font-weight: bold;
  color: #385600;
}
.contact__info {
  color: #385600;
  font-size: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .contact__info {
    font-size: 2rem;
  }
}
.contact__hero {
  margin-top: 2rem;
  text-align: center;
}

.covid {
  border: 2px solid rgba(255, 0, 0, 0.4);
  padding: 1rem;
  border-radius: 3px;
}
.covid__title {
  color: rgba(255, 0, 0, 0.7);
  font-size: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .covid__title {
    font-size: 2.4rem;
  }
}

.event {
  margin-top: 2rem;
}
.event__title {
  color: #385600;
  font-size: 4rem;
}
@media only screen and (max-width: 37.5em) {
  .event__title {
    font-size: 3rem;
  }
}
.event__banner {
  margin: 1rem 0;
  display: flex;
  justify-content: space-around;
}
.event__img {
  height: auto;
  border-radius: 3px;
  flex: 1 1;
}
.event__img:not(:last-child) {
  margin-right: 0.5rem;
}
.event__performances {
  margin-top: 2rem;
}
.event__performances-title {
  color: #385600;
  padding: 1rem 0;
  font-size: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .event__performances-title {
    font-size: 2.4rem;
  }
}

.filter {
  font-size: 2rem;
  display: flex;
  margin-top: 2rem;
}
.filter__button--selected {
  width: 50%;
  font-size: 2.2rem;
  padding: 1rem 2rem;
  border-radius: 3px;
  text-decoration: none;
  background-color: #738c31;
  color: white;
  text-align: center;
}
.filter__button--selected:not(:last-child) {
  margin-right: 1rem;
}
.filter__button--selected:hover {
  cursor: pointer;
}
@media only screen and (max-width: 37.5em) {
  .filter__button--selected {
    margin-top: 1rem;
    width: 100%;
  }
}
.filter__button--outline {
  width: 50%;
  font-size: 2.2rem;
  padding: 1rem 2rem;
  border-radius: 3px;
  text-decoration: none;
  background-color: #fff;
  color: #385600;
  text-align: center;
  border: 1px solid #385600;
}
.filter__button--outline:not(:last-child) {
  margin-right: 1rem;
}
.filter__button--outline:hover {
  cursor: pointer;
}
@media only screen and (max-width: 37.5em) {
  .filter__button--outline {
    margin-top: 1rem;
    width: 100%;
  }
}

.hero {
  width: 100%;
}
.hero__img {
  width: 100%;
  border-radius: 6px;
}
.hero__img--small {
  width: 50%;
}
@media only screen and (max-width: 37.5em) {
  .hero__img--small {
    width: 100%;
  }
}

.performance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 3px;
  min-height: 8rem;
  padding: 1rem;
  background-color: rgba(115, 140, 49, 0.07);
}
@media only screen and (max-width: 37.5em) {
  .performance {
    justify-content: center;
    margin-bottom: 1rem;
    border: 1px solid #385600;
    flex-direction: column;
    font-size: 1.6rem;
  }
}
.performance:hover {
  min-height: 8rem;
  border: 2px solid #385600;
}
@media only screen and (max-width: 37.5em) {
  .performance:hover {
    border: 1px solid #385600;
  }
}
.performance--directions {
  margin: 0 1rem;
}
.performance__details {
  width: 50% !important;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 37.5em) {
  .performance__details {
    width: 100% !important;
  }
}
.performance__group {
  display: flex;
  flex-direction: column;
  width: 25%;
}
@media only screen and (max-width: 56.75em) {
  .performance__group {
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .performance__group {
    width: 100%;
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .performance__location {
    font-weight: bold;
  }
}
.performance__ticket-link {
  width: 20%;
  padding: 1rem 2rem;
  border-radius: 3px;
  margin-right: 1rem;
  text-decoration: none;
  background-color: #738c31;
  color: white;
  text-align: center;
}
@media only screen and (max-width: 37.5em) {
  .performance__ticket-link {
    margin-top: 1rem;
    margin-right: 0;
    width: 100%;
  }
}
.performance__ticket-link--outline {
  width: 20%;
  padding: 1rem 2rem;
  border-radius: 3px;
  margin-right: 1rem;
  text-decoration: none;
  background-color: #fff;
  color: #385600;
  text-align: center;
  border: 1px solid #385600;
}
@media only screen and (max-width: 37.5em) {
  .performance__ticket-link--outline {
    margin-top: 1rem;
    margin-right: 0;
    width: 100%;
  }
}
.performance__soldout {
  color: red;
}
.performance__available {
  color: #385600;
}

.under-construction {
  text-align: center;
  max-width: 100%;
  padding: 5rem;
}
.under-construction__img {
  max-width: 100%;
}

.video {
  margin-top: 3rem;
  text-align: center;
  padding: 3rem;
}
.video__iframe {
  margin: 0 auto;
  border-radius: 3px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.2);
  height: 50rem;
  width: 89.1rem;
}
@media only screen and (max-width: 37.5em) {
  .video__iframe {
    width: 40rem;
    height: 22.2rem;
  }
}

.footer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  padding: 2rem;
}
.footer__container {
  margin-top: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer__text {
  color: #cc9900;
  letter-spacing: 1px;
  font-size: 2.3rem;
}
.footer__logo {
  display: inline-block;
}

.header {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 0;
  z-index: 1000;
}
@media only screen and (max-width: 56.75em) {
  .header {
    flex-direction: column;
  }
}
@media only screen and (max-width: 37.5em) {
  .header {
    padding-bottom: 0;
  }
}
.header__logo {
  height: 8rem;
  width: auto;
}
@media only screen and (max-width: 65.625em) {
  .header__logo {
    height: 8rem;
  }
}
@media only screen and (max-width: 56.75em) {
  .header__logo {
    height: 7rem;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 37.5em) {
  .header__logo {
    width: 100%;
    margin: 0 auto;
  }
}
.header__nav--right {
  color: #738c31;
}
@media only screen and (max-width: 65.625em) {
  .header__nav--right {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 56.75em) {
  .header__nav--right {
    display: none;
  }
}

.nav {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
@media only screen and (max-width: 65.625em) {
  .nav {
    text-align: center;
  }
}
.nav__link {
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 1.8rem;
  color: #333;
  transition: color 0.2s;
  padding: 1rem;
}
@media only screen and (max-width: 37.5em) {
  .nav__link {
    font-size: 1.5rem;
  }
}
.nav__link:hover {
  color: #385600;
}

.page__title {
  color: #385600;
  font-size: 4rem;
}
@media only screen and (max-width: 37.5em) {
  .page__title {
    font-size: 3rem;
  }
}
.page__single-cta {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 3rem auto;
}
@media only screen and (max-width: 37.5em) {
  .page__single-cta {
    flex-direction: column;
    width: 100%;
  }
}
.page__single-cta--button {
  display: inline-block;
  padding: 1.5rem 5rem;
  background-color: #738c31;
  color: white;
  font-size: 3rem;
  text-decoration: none;
  border-radius: 3px;
  transition: background-color 0.2s;
}
.page__single-cta--button:hover {
  background-color: #385600;
}
@media only screen and (max-width: 37.5em) {
  .page__single-cta--button {
    text-align: center;
    width: 100%;
    font-size: 2rem;
  }
  .page__single-cta--button:not(:last-child) {
    margin-bottom: 2rem;
  }
}
.page__cta {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5rem;
  margin-top: 3rem;
}
@media only screen and (max-width: 56.75em) {
  .page__cta {
    width: 100%;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .page__cta {
    grid-gap: 0.25rem;
  }
}
.page__cta--button {
  width: 100%;
  display: inline-block;
  padding: 1.5rem 5rem;
  background-color: #738c31;
  color: white;
  font-size: 3rem;
  text-decoration: none;
  text-align: center;
  border-radius: 3px;
  transition: background-color 0.2s;
}
.page__cta--button:hover {
  cursor: pointer;
  background-color: #385600;
}
@media only screen and (max-width: 37.5em) {
  .page__cta--button {
    text-align: center;
    width: 100%;
    font-size: 2rem;
  }
  .page__cta--button:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.harp-camp__hero {
  height: 50rem;
  background-size: cover;
  background-position: 0 -3rem;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 65.625em) {
  .harp-camp__hero {
    background-position: 0 -3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .harp-camp__hero {
    background-position: center;
  }
}
.harp-camp__description {
  background-color: #e8e7e7;
  padding: 3rem;
}
.harp-camp__title {
  text-align: center;
  color: #738c31;
  margin-bottom: 2rem;
}
.harp-camp__summary {
  text-align: center;
  padding: 0 15rem;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .harp-camp__summary {
    padding: 0 2rem;
  }
}
.harp-camp__info {
  padding: 3rem;
  display: flex;
  justify-content: space-around;
}
@media only screen and (max-width: 56.75em) {
  .harp-camp__info {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 37.5em) {
  .harp-camp__info {
    padding: 0;
  }
}
.harp-camp__class {
  padding: 2rem 3.5rem;
  text-align: left;
  width: 45%;
}
@media only screen and (max-width: 56.75em) {
  .harp-camp__class {
    width: 90%;
  }
}
@media only screen and (max-width: 37.5em) {
  .harp-camp__class {
    padding: 2rem 0;
  }
}
.harp-camp__class--img {
  width: 100%;
}
.harp-camp__class--blurb {
  font-size: 1.7rem;
}
.harp-camp__cta {
  text-align: center;
}
.harp-camp__register-btn {
  border: none;
  background-color: #385600;
  padding: 1rem 3rem;
  color: white;
  font-size: 1.8rem;
  text-transform: uppercase;
  transition: background-color 0.3s;
  border-radius: 2px;
  margin: 1rem;
}
.harp-camp__register-btn:hover {
  cursor: pointer;
  background-color: #738c31;
}
.harp-camp__workshops {
  padding: 3rem;
}
.harp-camp__workshops--row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1rem;
}
.harp-camp__workshops--title-container {
  margin-right: 2rem;
  width: 30%;
}
.harp-camp__workshops--title-container > h4 {
  text-align: right;
}
.harp-camp__workshops--text-container {
  width: 60%;
  text-align: left;
}
.harp-camp__faq {
  margin-top: 3rem;
  background-color: #e8e7e7;
  padding: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .harp-camp__faq {
    padding: 3rem 0;
  }
}
.harp-camp__faq--row {
  margin: 2rem;
  margin-top: 3rem;
}
.harp-camp__faq--blurb {
  margin: 0 auto;
  text-align: center;
  width: 50%;
  font-size: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .harp-camp__faq--blurb {
    width: 80%;
  }
}
.harp-camp__success {
  padding: 3rem;
  text-align: center;
  background-color: #e8e7e7;
}
.harp-camp__success a {
  display: inline-block;
  margin-top: 2rem;
}

.recordings {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.register__cta {
  background-color: #e8e7e7;
  padding: 2rem;
}
.register__return {
  position: fixed;
  top: 80%;
  right: 0;
}
.register__return--btn {
  outline: none;
  border: none;
  height: 5rem;
  padding: 1rem 2rem;
  padding-left: 5rem;
  background: linear-gradient(135deg, white 3.2rem, #cc9900, #cc9900 3.2rem);
  color: white;
}
.register__return--btn:hover {
  cursor: pointer;
  background: linear-gradient(135deg, white 3.2rem, #738c31, #738c31 3.2rem);
}
.register__form {
  margin-top: 3rem;
  display: block;
  width: 100%;
  min-height: 120rem;
}

.payment {
  margin: 0 auto;
  width: 100%;
  padding: 5rem;
  text-align: center;
  background-color: #e8e7e7;
}
.payment__instructions {
  margin: 0 auto;
}
.payment__form {
  margin: 0 auto;
  margin-top: 3rem;
  border: 1px solid #333;
  padding: 2rem;
  padding-top: 3rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.payment__form > * {
  margin-top: 2rem;
}

/*# sourceMappingURL=index.css.map */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

